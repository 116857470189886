<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getCommSsoUserInfo,getWaterMarkTxt } from '@/api/wx.js'
import { removeWatermark, setWaterMark } from '@/utils/watermark'
export default {
  data() {
    return {}
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getCommSsoUserInfo({ guid: this.$route.query.guid }).then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.userinfo[0]))
        sessionStorage.setItem('userInfo', JSON.stringify(res.userinfo[0]))
        getWaterMarkTxt({
          username:res.userinfo[0].username
        }).then(res=>{
          const ressy = res.data;
          if(ressy && ressy.length > 0){
            const syobj = ressy[0];
            if(syobj.show_watermark == 1){
              localStorage.setItem("sysShuiYin",JSON.stringify(syobj))
              setWaterMark(
                syobj.watermark_line1,
                syobj.watermark_line2,
                syobj.watermark_line3
              )
            }else{
              localStorage.removeItem("sysShuiYin")
            }
          }
        })
        localStorage.setItem('v8_login_pwd', res.userinfo[0].pwd)
        localStorage.setItem('v8_login_username', res.userinfo[0].username)
        Toast.clear()
        this.$router.replace('/' + res.pathinfo[0].path)
      })
    }
  }
}
</script>

<style></style>
